<template>
  <div>
    <div class="row">
      <div class="col">
        <!-- Featured List -->
        <featured-list :update="updateFeatured"
                       @update-featured-table="updateFeaturedTable"
                       @update-media-table="updateMediaTable">
        </featured-list>
      </div>

      <div class="col">
        <!-- Media List -->
        <media-list :update="updateMedia"
                    @update-media-table="updateMediaTable"
                    @update-featured-table="updateFeaturedTable">
        </media-list>
      </div>
    </div>

    <!-- Dialog -->
    <!-- <v-dialog/> -->
  </div>
</template>

<script>
  const MediaList = () => import('@/components/admin/featured/MediaList.vue');
  const FeaturedList =
    () => import('@/components/admin/featured/FeaturedList.vue');

  export default {
    name : 'Featured',
    data() {
      return {
        updateFeatured : false,
        updateMedia    : false,
      }
    },
    components : {
      MediaList,
      FeaturedList,
    },
    methods : {

      /**
       * Update Featured Table
       */
      updateFeaturedTable() {
        this.updateFeatured = (this.updateFeatured) ? false : true;
      },

      /**
       * Update Media Table
       */
      updateMediaTable() {
        this.updateMedia = (this.updateMedia) ? false : true;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/admin/featured/featured-media";
</style>
